/* eslint-disable prettier/prettier */
import { NgxLoggerLevel } from "ngx-logger";
import { IBaseConfig } from "../config/config.model";

export const commonEnvironment: IBaseConfig = {
    production: false,
    APP_VERSION: '3.1.1-beta-15',
    APP_BASE_PATH: 'https://portal.waki.me',
    API_VERSION: '3',
    API_BASE_PATH: 'https://api.waki.me',
    IMAGE_BASE_PATH: 'https://resources.waki.me',
    SOCKET_URL: 'wss://api.waki.me',
    RECAPTCHA_SITE_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    logging: {
        disableConsoleLogging: false,
        logLevel: NgxLoggerLevel.DEBUG,
        enableSourceMaps: true,
        serverLoggingUrl: null,
    },
    featureFlags: {
        enableUserAccess: true
    },
    recaptcha: {
        siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    },
}
